import { Fragment, useState } from 'react'
import { useDebounce } from 'react-use'

import { PlusOutlined } from '@ant-design/icons'
import { Button, Form, Select, Spin } from 'antd'

import { useProductInfinite } from 'hooks/product/useProductInfinite'

function FormLinkedProducts() {
  const [keyword, setKeyword] = useState('')
  const [search, setSearch] = useState('')

  const {
    data: products,
    hasNextPage,
    fetchNextPage,
    isFetching,
  } = useProductInfinite({
    search,
    isPublish: true,
  })

  useDebounce(
    () => {
      setSearch(keyword)
    },
    500,
    [keyword],
  )

  return (
    <Fragment>
      <Form.Item name="linkedProductIds" label="Linked Product">
        <Select
          mode="multiple"
          optionLabelProp="label"
          onSearch={setKeyword}
          onChange={() => setKeyword('')}
          onBlur={() => setKeyword('')}
          placeholder="Search product by name"
          notFoundContent={isFetching ? <Spin size="small" /> : null}
          filterOption={false}
          autoClearSearchValue={true}
          options={products.map((product) => ({
            label: product.title,
            value: product._id,
          }))}
          dropdownRender={(menu) => (
            <Fragment>
              {menu}

              {hasNextPage && (
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => fetchNextPage()}
                  loading={isFetching}
                  block
                >
                  Load more
                </Button>
              )}
            </Fragment>
          )}
        />
      </Form.Item>
    </Fragment>
  )
}

export default FormLinkedProducts
