import { useContext, useEffect, useMemo } from 'react'

import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  Modal,
  Row,
  Tag,
  Typography,
} from 'antd'

import { MultiplierModalContext } from './MultiplierModalContext'

import { useMultiplier } from 'hooks/multiplier/useMultiplier'
import { useMultipliers } from 'hooks/multiplier/useMultipliers'
import { useCreateMultiplier } from 'hooks/multiplier/useCreateMultiplier'
import { useUpdateMultiplier } from 'hooks/multiplier/useUpdateMultiplier'

import { isValidAddress, notifyError, notifySuccess } from 'helper'

import { CreateMultiplierDto, CriteriaType } from 'types/multiplier.type'

import './index.less'

function CreateAndUpdateMultiplier() {
  const [form] = Form.useForm<CreateMultiplierDto>()
  const name = Form.useWatch('name', form)
  const multiplier = Form.useWatch('multiplier', form)
  const contractAddress = Form.useWatch(
    ['configs', 'nftConfig', 'contractAddress'],
    form,
  )

  const { isModalOpen, setIsModalOpen, multiplierId, setMultiplierId } =
    useContext(MultiplierModalContext)

  const isEdit = !!multiplierId

  const { data: multiplierDetails } = useMultiplier(multiplierId ?? '')
  const { mutateAsync: createMultiplier, isLoading: isCreating } =
    useCreateMultiplier()
  const { mutateAsync: updateMultiplier, isLoading: isUpdating } =
    useUpdateMultiplier()
  const { refetchRoot } = useMultipliers({})

  const isDisabled = useMemo(() => {
    return !(
      name?.trim().length > 0 &&
      Number.isInteger(multiplier) &&
      multiplier > 0
    )
  }, [name, multiplier])

  const handleSubmit = async (values: CreateMultiplierDto) => {
    try {
      if (!values.configs.whitelistConfig?.whitelistId) {
        values.configs.whitelistConfig = undefined
      }
      if (!contractAddress) {
        values.configs.nftConfig = undefined
      }
      if (isEdit) {
        await updateMultiplier({
          multiplierId,
          payload: values,
        })
      } else {
        await createMultiplier(values)
      }

      await refetchRoot()
      notifySuccess(isEdit ? 'Updated Multiplier' : 'Created Multiplier')
      setIsModalOpen(false)
      setMultiplierId(undefined)
    } catch (error) {
      notifyError(error)
    }
  }

  useEffect(() => {
    if (multiplierDetails) {
      form.setFieldsValue(multiplierDetails)
    }

    return () => form.resetFields()
  }, [form, multiplierDetails])

  return (
    <Modal
      open={isModalOpen}
      footer={null}
      onCancel={(e) => {
        e.stopPropagation()
        setIsModalOpen(false)
        setMultiplierId(undefined)
        form.resetFields()
      }}
      destroyOnClose
      style={{ maxWidth: 450 }}
      wrapClassName="create-update-multiplier-modal"
    >
      <Row gutter={[0, 24]}>
        <Col span={24}>
          <Typography.Title
            level={3}
            type="success"
            style={{ textAlign: 'center', marginBottom: 16 }}
          >
            {isEdit ? 'Update Multiplier' : 'Create Multiplier'}
          </Typography.Title>
        </Col>
        <Col span={24}>
          <Form form={form} layout="vertical" onFinish={handleSubmit}>
            <Form.Item
              name="name"
              label="Multiplier Name"
              rules={[
                { required: true, message: 'Please enter multiplier name' },
                {
                  max: 30,
                  message: 'Name must be 30 characters max!',
                },
                {
                  validator: (_, value) => {
                    if (value && value.trim().length === 0) {
                      return Promise.reject(
                        new Error('Name cannot be just spaces'),
                      )
                    }
                    return Promise.resolve()
                  },
                },
              ]}
            >
              <Input placeholder="Name Multiplier" />
            </Form.Item>

            <Form.Item
              name="multiplier"
              label="Multiplier Percent"
              rules={[
                {
                  required: true,
                  type: 'number',
                  message: 'Please enter multiplier percent',
                },
                {
                  validator: (_, value) => {
                    if (Number.isInteger(value)) {
                      return Promise.resolve()
                    }
                    return Promise.reject(
                      new Error('Please enter an integer number'),
                    )
                  },
                },
              ]}
            >
              <InputNumber
                min={0}
                max={100}
                style={{ width: '100%' }}
                placeholder="0"
                formatter={(value) =>
                  `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                addonAfter={
                  <Tag title="Extra information" color="green">
                    =
                    {Math.floor(
                      (form.getFieldValue(['multiplier']) ?? 0) * 10000,
                    ) / 100}
                    %
                  </Tag>
                }
              />
            </Form.Item>

            <Form.Item
              name={['configs', 'nftConfig', 'contractAddress']}
              label="Contract Address (Optional)"
              rules={[
                {
                  validator: (_, value) => {
                    if (value && !isValidAddress(value)) {
                      return Promise.reject('Invalid address')
                    }
                    return Promise.resolve()
                  },
                },
              ]}
            >
              <Input placeholder="Contract Address" />
            </Form.Item>

            {!!contractAddress && (
              <Form.Item
                name={['configs', 'nftConfig', 'amount']}
                initialValue={1}
                hidden
              />
            )}

            <Form.Item
              name={['configs', 'whitelistConfig', 'whitelistId']}
              initialValue={''}
              hidden
            />

            <Form.Item name="bountyIds" initialValue={[]} hidden />

            <Form.Item name="isActive" initialValue={true} hidden />

            <Form.Item
              name="criteriaType"
              initialValue={CriteriaType.Whitelist}
              hidden
            />

            <Button
              type="primary"
              disabled={isDisabled}
              htmlType="submit"
              style={{ width: '100%' }}
              loading={isCreating || isUpdating}
            >
              {isEdit ? 'Save Change' : 'Create Multiplier'}
            </Button>
          </Form>
        </Col>
      </Row>
    </Modal>
  )
}

export default CreateAndUpdateMultiplier
