import { marketplaceApi as api } from 'services/base-axios'
import { getAccessToken } from 'services/utils'

import configs from 'configs'

import type { Data, DataList } from 'types'
import type {
  CheckWalletWhitelistsExisted,
  CreateWhitelistDto,
  DeleteUserWhitelistDto,
  FindAllWhitelistDto,
  FindWhitelistWallets,
  IUserWhitelist,
  UpdateWhitelistDto,
  WhitelistData,
} from 'types/whitelist.type'

const CONTROLLER = '/admin/whitelist'
const USER_WHITELIST_CONTROLLER = '/admin/user-whitelist'

class Whitelist {
  async getById(id: string): Promise<WhitelistData> {
    const { data } = await api.get(`${CONTROLLER}/${id}`)
    return data
  }

  async getAll(
    params: FindAllWhitelistDto,
  ): Promise<DataList<WhitelistData[]>> {
    const { data } = await api.get(CONTROLLER, {
      params,
    })
    return data
  }

  async create(dto: CreateWhitelistDto): Promise<WhitelistData> {
    const { data } = await api.post(CONTROLLER, dto)
    return data
  }

  async update(id: string, dto: UpdateWhitelistDto): Promise<WhitelistData> {
    const { data } = await api.patch(`${CONTROLLER}/${id}`, dto)
    return data
  }

  async deleteUserWhitelist(dto: DeleteUserWhitelistDto) {
    const { data } = await api.delete(
      `${USER_WHITELIST_CONTROLLER}/delele-many`,
      { params: { ...dto } },
    )

    return data
  }

  getDownloadUserWhitelistUrl(whitelistIds: string[]) {
    const accessToken = getAccessToken()

    const searchParams = new URLSearchParams()
    whitelistIds.forEach((whitelistId) =>
      searchParams.append('whitelistIds', whitelistId),
    )
    searchParams.append('accessToken', accessToken)

    const url = new URL(
      configs.api.marketplaceApi +
        CONTROLLER +
        '/export-whitelist?' +
        searchParams,
    )

    return url.toString()
  }

  async getUserWhitelist(
    dto: FindWhitelistWallets,
  ): Promise<DataList<[Data<IUserWhitelist>]>> {
    const { data } = await api.get(USER_WHITELIST_CONTROLLER, {
      params: {
        ...dto,
      },
    })

    return data
  }

  async checkWalletWhitelistsExisted(
    dto: CheckWalletWhitelistsExisted,
  ): Promise<[string]> {
    const { data } = await api.get(
      `${USER_WHITELIST_CONTROLLER}/existed-wallets`,
      {
        params: {
          ...dto,
        },
      },
    )

    return data
  }

  getDownloadWalletWhitelistUrl(whitelistId: string) {
    const accessToken = getAccessToken()
    const paramsObj = { whitelistId, accessToken }
    const searchParams = new URLSearchParams(paramsObj)
    const url = new URL(
      configs.api.marketplaceApi +
        `${USER_WHITELIST_CONTROLLER}/export-wallet-whitelists?` +
        searchParams,
    )

    return url.toString()
  }
}

export const WhitelistService = new Whitelist()
