import { FindDto } from 'types'

export type INFTMultiplierConfig = {
  contractAddress: string
  amount: number
}

export type IWhitelistMultiplierConfig = {
  whitelistId: string
}

export enum CriteriaType {
  Nft = 'CRITERIA_TYPE:NFT',
  Whitelist = 'CRITERIA_TYPE:WHITELIST',
}
export type IMultiplier = {
  _id: string
  name: string
  multiplier: number
  isActive: boolean
  criteriaType: CriteriaType
  configs: {
    nftConfig?: INFTMultiplierConfig
    whitelistConfig?: IWhitelistMultiplierConfig
  }
}

export type IMultiplierApplication = {
  _id: string
  applicationId: string
  multiplierId: string
  applicationType: ApplicationType
}

export type CreateMultiplierDto = {
  name: string
  multiplier: number
  isActive: boolean
  criteriaType: CriteriaType
  configs: {
    nftConfig?: INFTMultiplierConfig
    whitelistConfig?: IWhitelistMultiplierConfig
  }
}

export type BulkCreateMultiAppDto = {
  applicationType: ApplicationType
  applicationIds: string[]
  multiplierId: string
}

export type GetMultiplierApplications = {
  multiplierId: string
  applicationType: ApplicationType
}

export type UpdateMultiplierDto = Partial<CreateMultiplierDto>

export enum MultiplierStatus {
  OnGoing = 'MULTIPLIER_STATUS::ONGOING',
  Unpublished = 'MULTIPLIER_STATUS::UNPUBLISHED',
}

export enum FindMultiplierSort {
  Newest = 'NEWEST',
  Oldest = 'OLDEST',
}

export type FindMultipliersDto = FindDto & {
  bountyId?: string
  isActive?: boolean
  sort?: FindMultiplierSort
}

export enum ApplicationType {
  Bounty = 'APPLICATION_TYPE:BOUNTY',
  Redemption = 'APPLICATION_TYPE:REDEMPTION',
}
