import numbro from 'numbro'
import hash from 'object-hash'
import slugify from 'slugify'
import moment, { type Moment } from 'moment'

export const notifySuccess = (content: string) => {
  return window.notify({
    type: 'success',
    description: `${content} successfully.`,
  })
}

export const notifyError = (err: any) => {
  return window.notify({
    type: 'error',
    description: err?.response?.data?.message || err.message || err,
  })
}

export const shortenAddress = (address: string, num = 4, delimiter = '...') => {
  if (!address) return ''
  return (
    address.substring(0, num) +
    delimiter +
    address.substring(address.length - num, address.length)
  )
}

/**
 * Get token Logo
 * @param tokenName - string
 * @returns
 */
export const getTokenLogo = (tokenName: string) => {
  try {
    return require(`static/images/tokenLogo/${tokenName}.png`)
  } catch (err) {
    return ''
  }
}

/**
 * Wrapped Numbro - https://numbrojs.com/old-format.html
 * @param value - value
 * @returns
 */
export const numeric = (
  value?: number | string | bigint,
): ReturnType<typeof numbro> => {
  if (!value) return numbro('0')
  return numbro(value)
}

export const randomColor = () =>
  `#${Math.floor(Math.random() * 16777215).toString(16)}`

export const shortenAddressCustom = (
  address: string | undefined,
  prefixNum = 6,
  suffixNum = 4,
  delimiter = '...',
) => {
  if (!address) return ''
  return `${address.substring(0, prefixNum)}${delimiter}${address.substring(
    address.length - suffixNum,
    address.length,
  )}`
}

export const encode = (key: string) => {
  return key.toLowerCase().replace(/ /g, '_')
}

export const compare = (
  ob1: Record<string, string>,
  ob2: Record<string, string>,
) => {
  const encode = (ob: Record<string, string>) => {
    const newObject: Record<string, string> = {}
    for (const key in ob) {
      newObject[key.toLowerCase()] = ob[key].toLowerCase()
    }
    return newObject
  }
  return hash(encode(ob1)) === hash(encode(ob2))
}

export const convertToStartCase = (str: string) => {
  return str
    .replace(/([A-Z])/g, ' $1')
    .replace(/[_-]/g, ' ')
    .replace(/\s+/g, ' ')
    .trim()
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ')
}

export const getSpecialChars = () => {
  const specialChars = /[`!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?~]/

  return specialChars
}

export const generateSlug = (title: string) => {
  return slugify(title, { lower: true, remove: getSpecialChars(), trim: true })
}

export const convertTokenUrl = (url: string) => {
  if (!url) return ''

  const urlObj = new URL(url)

  if (urlObj.protocol === 'ipfs:') {
    const pathname = urlObj.pathname.replace(/^\/\//, '')
    return `https://ipfs.io/ipfs/${pathname}`
  }

  return url
}

/**
 * NOTE: This fn is ONLY used to convert local timezone to UTC before upload to BE.
 *
 * Not used to show time on FE
 */
export const convertTzUtcBeforeUpload = (date?: Moment) => {
  return moment
    .utc(date)
    .utcOffset(-moment.parseZone().utcOffset())
    .toISOString()
}

export const ellipsisText = (str: string, num = 4, delimiter = '...') => {
  if (str.length > num + delimiter.length)
    return str.substring(0, num - delimiter.length) + delimiter
  return str
}

export const thousandSeparator = (value: number | string | undefined) => {
  if (!value && value !== 0) return ''

  return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
}

// This function shortens the decimal part of a number
export const shortenDecimal = (amount: number, maxDecimal = 8): string => {
  return numbro(amount).format({
    mantissa: maxDecimal,
    trimMantissa: true,
    roundingFunction: (num) => Math.floor(num),
  })
}

export const isValidAddress = (address: string): boolean => {
  const isValidFormat = /^0x[a-fA-F0-9]{40}$/.test(address)
  return isValidFormat
}

export const validateInput = (
  value: string,
  minValue: number = 0,
  maxValue: number,
) => {
  const trimValue = value.trim()
  return trimValue.length >= minValue && trimValue.length <= maxValue
}
