import { useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import ApplicationFrame from '../applicationManagement/applicationFrame'

import { useRedemptions } from 'hooks/redemption/useRedemptions'
import { useMultiApps } from 'hooks/multiplier/useMultiApps'

import { ApplicationFrameContext } from '../applicationManagement/applicationFrame/ApplicationFrameContext'

import { ApplicationType } from 'types/multiplier.type'

export default function RedemptionApplication() {
  const multiplierId = useParams().multiplierId || ''
  const [search, setSearch] = useState('')
  const [selectedRedemptions, setSelectedRedemptions] = useState<string[]>([])
  const { data: redemptions } = useRedemptions({ search })
  const { data: appliedRedemptions } = useMultiApps({
    multiplierId,
    applicationType: ApplicationType.Redemption,
  })
  const applications = useMemo(() => {
    const appliedApplicationIds =
      appliedRedemptions?.map((app) => app.applicationId) || []
    const filteredApplications = Object.values(redemptions).filter(
      (redemption) => !appliedApplicationIds.includes(redemption._id),
    )

    return Object.values(filteredApplications).map((redemption) => ({
      name: `BadgeId ${redemption.badgeId}`,
      id: redemption._id,
    }))
  }, [redemptions, appliedRedemptions])

  return (
    <ApplicationFrameContext.Provider
      value={{
        applicationType: ApplicationType.Redemption,
        search,
        setSearch,
        selectedApplications: selectedRedemptions,
        setSelectedApplications: setSelectedRedemptions,
      }}
    >
      <ApplicationFrame
        applications={applications}
        appliedApplications={appliedRedemptions || []}
      />
    </ApplicationFrameContext.Provider>
  )
}
