import type { Data, FindDto } from 'types/index'

export interface IWhitelist {
  title: string

  description: string

  thumbnail?: string

  inputPlaceholder?: string
}

export type FindAllWhitelistDto = {
  search?: string
} & FindDto
export type CreateWhitelistDto = IWhitelist
export type UpdateWhitelistDto = Partial<IWhitelist>

export type WhitelistData = Data<IWhitelist>

export type IWalletWhitelist = {
  wallet: string
}

export interface IUserWhitelist {
  uid?: string

  whitelistId: string

  address?: string

  receiverEmail?: string

  receiverAddress?: string
}

export type DeleteUserWhitelistDto = {
  whitelistId: string
  addresses: string[]
}

export enum WhitelistField {
  Address = 'WHITELIST_FIELD:ADDRESS',
  ReceiverEmail = 'WHITELIST_FIELD:RECEIVER_EMAIL',
  ReceiverAddress = 'WHITELIST_FIELD:RECEIVER_ADDRESS',
  Uid = 'WHITELIST_FIELD:UID',
}

export type FindWhitelistWallets = {
  whitelistId: string
  byField?: WhitelistField
} & FindDto

export type CheckWalletWhitelistsExisted = {
  whitelistId: string
  addresses?: string[]
}
