import { Fragment, useState } from 'react'
import { useDebounce } from 'react-use'

import { PlusOutlined } from '@ant-design/icons'
import { Button, Form, Select, Spin } from 'antd'

import { useGachaInfinite } from 'hooks/gacha/useGachaInfinite'

import { GachaStates } from 'types/gacha.type'

function FormItemGacha() {
  const [keyword, setKeyword] = useState('')
  const [search, setSearch] = useState('')

  const {
    data: listGacha,
    hasNextPage,
    fetchNextPage,
    isFetching,
  } = useGachaInfinite({ search, state: GachaStates.OnGoing, pageSize: 20 })

  useDebounce(
    () => {
      setSearch(keyword)
    },
    500,
    [keyword],
  )

  return (
    <Fragment>
      <Form.Item name="linkedGachaIds" label="Linked Gacha">
        <Select
          mode="multiple"
          optionLabelProp="label"
          onSearch={setKeyword}
          onChange={() => setKeyword('')}
          onBlur={() => setKeyword('')}
          placeholder="Search gacha by name"
          notFoundContent={isFetching ? <Spin size="small" /> : null}
          filterOption={false}
          autoClearSearchValue={true}
          options={listGacha.map((gacha) => ({
            label: gacha.title,
            value: gacha._id,
          }))}
          dropdownRender={(menu) => (
            <Fragment>
              {menu}

              {hasNextPage && (
                <Button
                  type="primary"
                  icon={<PlusOutlined />}
                  onClick={() => fetchNextPage()}
                  loading={isFetching}
                  block
                >
                  Load more
                </Button>
              )}
            </Fragment>
          )}
        />
      </Form.Item>
    </Fragment>
  )
}

export default FormItemGacha
