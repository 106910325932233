import { useInfiniteQuery } from 'react-query'

import { ProductService } from 'services/marketplace/product'

import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from 'constant'

type ProductInfiniteParams = {
  search?: string
  page?: number
  pageSize?: number
  isPublish?: boolean
  isDeleted?: boolean
  isEnded?: boolean
}

export const useProductInfinite = ({
  page = DEFAULT_PAGE,
  pageSize = DEFAULT_PAGE_SIZE,
  ...other
}: ProductInfiniteParams | undefined = {}) => {
  const { data, ...rest } = useInfiniteQuery({
    queryKey: ['GET_PRODUCT_INFINITE', { ...other, page, pageSize }],
    queryFn: async ({ pageParam: page = 1 }) => {
      const { data, total } = await ProductService.find({
        ...other,
        offset: (page - 1) * pageSize,
        limit: pageSize,
      })

      return {
        nextPage: page < Math.ceil(total / pageSize) ? page + 1 : undefined,
        data,
      }
    },
    getNextPageParam: (lastPage) => lastPage.nextPage,
  })

  const productData = data?.pages.flatMap((page) => page.data)

  return {
    ...rest,
    data: productData ?? [],
  }
}
