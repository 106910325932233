import { Fragment, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'

import { useTasks } from 'hooks/task/useTasks'
import { useTaskByBountyId } from 'hooks/task/useTaskByBountyId'
import { useBountySetup, useCreateBountyTasks, useFileList } from '../index'
import { DUMMY_BOUNTY_DATA, useBountyData } from 'hooks/bounty/useBountyData'
import { onConvertFileList } from 'components/systems/uploadPicture/upload'

const WatchBountyData = () => {
  const [, setBountyData] = useBountySetup()
  const [, setTasks] = useCreateBountyTasks()
  const bountyId = useParams().bountyId || ''
  const bountyData = useBountyData(bountyId)
  const taskIds = useTaskByBountyId(bountyId)
  const tasks = useTasks()
  const [, setFileList] = useFileList()

  const formatTasksData = useMemo(() => {
    if (!taskIds) return
    const nextTasks = []
    for (const taskId of taskIds) {
      const {
        type,
        configs,
        title,
        description,
        verificationMethod,
        isPublished,
        isOptional,
      } = tasks[taskId]

      nextTasks.push({
        type,
        id: taskId,
        description,
        title,
        configs,
        verificationMethod,
        isPublished,
        isOptional,
      })
    }
    return nextTasks
  }, [taskIds, tasks])

  useEffect(() => {
    const data = bountyId && bountyData ? bountyData : DUMMY_BOUNTY_DATA
    setBountyData(data)
  }, [bountyData, bountyId, setBountyData])

  useEffect(() => {
    const taskData = bountyId && formatTasksData ? (formatTasksData as any) : []
    setTasks(taskData)
  }, [bountyId, formatTasksData, setTasks])

  useEffect(() => {
    const thumbnail = bountyData.thumbnail
    if (!thumbnail) return
    const fileList = onConvertFileList([thumbnail])
    setFileList(fileList)
  }, [bountyData.thumbnail, setFileList])

  return <Fragment />
}

export default WatchBountyData
