import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { FederatedApiService } from 'services/bountySystem/federatedApi'
import { TaskService } from 'services/bountySystem/task'
import { TaskConfigs, TaskType } from 'templates/types'
import { VerificationMethod } from 'types/task.type'

/**
 * Interface & Utility
 */

export type TaskData = {
  bountyId: string
  title: string
  description: string
  thumbnail: string
  type: TaskType
  recurrenceTime: number
  configs: TaskConfigs
  createdAt: string
  updatedAt: string
  verificationMethod: VerificationMethod
  isPublished: boolean
  isOptional?: boolean
}

export type TaskState = Record<string, TaskData>

/**
 * Store constructor
 */

const NAME = 'tasks'
const initialState: TaskState = {}

/**
 * Actions
 */

export const getTasksByBounty = createAsyncThunk(
  `${NAME}/getTasksByBounty`,
  async (bountyId: string) => {
    const bulk: TaskState = {}
    try {
      const data = await TaskService.getByBountyId(bountyId)
      for (const task of data) {
        if (task.type === TaskType.FederatedApi) {
          const {
            data: { headers, pattern },
          } = await FederatedApiService.getById(task.configs?.federatedApiId)
          const newHeaders = headers
            ? Object.keys(headers).map((headerKey) => ({
                key: headerKey,
                value: headers[headerKey],
              }))
            : []
          task.configs = {
            ...task.configs,
            pattern,
            headers: newHeaders,
          }
        }
        bulk[task._id] = task
      }
    } catch (error) {}

    return bulk
  },
)

export const upsetTask = createAsyncThunk<
  TaskState,
  { taskId: string; taskData: TaskData }
>(`${NAME}/upsetTask`, async ({ taskId, taskData }) => {
  return { [taskId]: taskData }
})

/**
 * Usual procedure
 */

const slice = createSlice({
  name: NAME,
  initialState,
  reducers: {},
  extraReducers: (builder) =>
    void builder
      .addCase(
        getTasksByBounty.fulfilled,
        (state, { payload }) => void Object.assign(state, payload),
      )
      .addCase(
        upsetTask.fulfilled,
        (state, { payload }) => void Object.assign(state, payload),
      ),
})

export default slice.reducer
