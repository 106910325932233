import { useState } from 'react'

import { Button, Col, Modal, Row, Space, Typography } from 'antd'

import UploadCSV from './UploadCsv'
import CsvPreview from './csvPreview'
import OnSystemPreview from './onSystemPreview'
import IonIcon from 'components/systems/ionIcon'

import { IWalletWhitelist } from 'types/whitelist.type'

type MultiplierWhitelistProps = {
  whitelistId: string
  isOnlyWallet?: boolean
}

function MultiplierWhitelist({
  whitelistId,
  isOnlyWallet,
}: MultiplierWhitelistProps) {
  const [open, setOpen] = useState(false)
  const [addressList, setAddressList] = useState<IWalletWhitelist[]>([])

  return (
    <Row gutter={[0, 32]} justify="end">
      <Col>
        <Button type="primary" onClick={() => setOpen(true)}>
          <Space>
            <IonIcon name="cloud-upload-outline" />
            <Typography.Text strong>Upload CSV</Typography.Text>
          </Space>
        </Button>
      </Col>

      <Col span={24}>
        <Row gutter={[20, 20]}>
          <Col span={12}>
            <CsvPreview
              whitelistId={whitelistId}
              addressList={addressList}
              onSetAddressList={setAddressList}
            />
          </Col>
          <Col span={12}>
            <OnSystemPreview
              whitelistId={whitelistId}
              isOnlyWallet={isOnlyWallet}
            />
          </Col>
        </Row>
      </Col>

      <Modal
        open={open}
        onCancel={() => setOpen(false)}
        footer={null}
        destroyOnClose={true}
      >
        <UploadCSV
          onUploadSuccess={() => setOpen(false)}
          onSetAddressList={setAddressList}
        />
      </Modal>
    </Row>
  )
}

export default MultiplierWhitelist
