import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import {
  Button,
  Card,
  Col,
  Dropdown,
  InputNumber,
  Row,
  Space,
  Typography,
} from 'antd'

import { FormCreateLoader } from 'templates'
import IonIcon from 'components/systems/ionIcon'

import {
  useBountySetup,
  useCreateBountyStep,
  useCreateBountyTasks,
  useUpdateBountyForm,
} from '../../../index'

import { notifyError, validateInput } from 'helper'

import { CreateAndUpdateTabs } from 'constant/bounty'
import { MENU_ITEMS } from './buttonNewTask/dropdownMenu'
import { SINGLE_ITEM_MENU } from './buttonNewTask/singleItemMenu'
import {
  TITLE_MAX_LEN,
  TITLE_MIN_LEN,
  DESC_MIN_LEN,
  DESC_MAX_LEN,
} from 'constant'

import {
  TaskBridgeConfigs,
  TaskInfo,
  TaskKeys,
  TaskPartnerConfigs,
} from 'templates/types'

import './index.less'

const FormEnterTask = () => {
  const [tasks, setTasks] = useCreateBountyTasks()
  const [, setStep] = useCreateBountyStep()
  const bountyId = useParams().bountyId || ''
  const isUpdateForm = !!bountyId
  const { onBountyUpdate, formLoading } = useUpdateBountyForm()
  const [bountyData, setBountyData] = useBountySetup()
  const [threshold, setThreshold] = useState<number>(
    bountyData.configs?.minOptionalTasks || 0,
  )
  const [errorMessage, setErrorMessage] = useState<string | null>(null)

  const handleUpdateBounty = () => {
    if (threshold !== null && !Number.isInteger(threshold)) {
      return notifyError('Please enter an integer value.')
    }

    if (threshold !== null && threshold > totalOptionalTasks) {
      return notifyError(
        'Threshold cannot be greater than the total optional tasks.',
      )
    }

    onBountyUpdate()
  }

  const onChange = useCallback(
    (index: number, taskInfo: TaskInfo) => {
      const tasksCloned = [...tasks]
      if (!tasksCloned[index]) return

      const task = { ...tasksCloned[index] }
      task.configs = { ...task.configs, ...taskInfo.configs }
      task.title = taskInfo.title
      task.description = taskInfo.description
      task.verificationMethod = taskInfo.verificationMethod
      task.isOptional = taskInfo.isOptional

      tasksCloned[index] = task
      return setTasks(tasksCloned)
    },
    [setTasks, tasks],
  )

  const totalOptionalTasks = tasks.filter(
    (task) => task.isOptional && task.isPublished,
  ).length

  const disabled = useMemo(() => {
    if (!tasks.length) return true
    for (let i = 0; i < tasks.length; i++) {
      const task = tasks[i]
      let config
      switch (task.key) {
        case TaskKeys.CustomizeVerifyAPI:
          config = task.configs as TaskPartnerConfigs
          if (!config.meta['endPoint']) return true
          break
        case TaskKeys.BridgeToken:
          config = task.configs as TaskBridgeConfigs
          if (!config.bridgeAddress) return true
          break
      }
    }

    const isValidTask = tasks.every((task) => {
      return (
        validateInput(task.title, TITLE_MIN_LEN, TITLE_MAX_LEN) &&
        validateInput(task.description, DESC_MIN_LEN, DESC_MAX_LEN)
      )
    })

    const isValidThreshold =
      Number.isInteger(threshold) && threshold <= totalOptionalTasks
    return !isValidTask || !isValidThreshold
  }, [tasks, threshold, totalOptionalTasks])

  useEffect(() => {
    const validateThreshold = () => {
      if (threshold === null) return

      if (!Number.isInteger(threshold)) {
        return setErrorMessage('Please enter an integer value.')
      }

      if (threshold > totalOptionalTasks) {
        return setErrorMessage(
          'Threshold cannot be greater than the total optional tasks.',
        )
      }

      setBountyData({
        ...bountyData,
        configs: { minOptionalTasks: threshold },
      })
      setErrorMessage(null)
    }

    validateThreshold()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalOptionalTasks, threshold])

  return (
    <Row gutter={[24, 24]}>
      {/* List tasks */}
      {!!tasks.length && (
        <Col span={24}>
          <Row gutter={[24, 24]}>
            {tasks.map((task, idx) => {
              const taskData = tasks[idx]
              return (
                <Col span={24} key={idx}>
                  <FormCreateLoader
                    taskInfo={{
                      taskId: task.id,
                      configs: taskData.configs,
                      title: taskData.title,
                      description: taskData.description,
                      verificationMethod: taskData.verificationMethod,
                      isPublished: taskData?.isPublished ?? true,
                      isOptional: taskData.isOptional,
                    }}
                    type={taskData.type}
                    onChange={(taskInfo) => onChange(idx, taskInfo)}
                  />
                </Col>
              )
            })}
          </Row>
        </Col>
      )}

      <Col span={24}>
        <Space size={8}>
          <Typography.Text>Optional Task Threshold: </Typography.Text>
          <InputNumber
            min={0}
            max={totalOptionalTasks}
            defaultValue={threshold}
            onChange={(value) =>
              setThreshold(typeof value === 'number' ? value : 0)
            }
            style={{ maxWidth: 50 }}
          />
          /<Typography.Text>{totalOptionalTasks}</Typography.Text>
        </Space>

        {errorMessage && (
          <Typography.Text type="danger" style={{ display: 'block' }}>
            {errorMessage}
          </Typography.Text>
        )}
      </Col>

      {/* Add new task */}
      <Col span={24}>
        <Typography.Text type="success">+ Add a task</Typography.Text>
      </Col>
      <Col span={24}>
        <Card style={{ background: 'transparent' }}>
          <Row gutter={[12, 12]}>
            {Object.keys(MENU_ITEMS).map((type: any) => {
              const key: keyof typeof MENU_ITEMS = type
              const configs = MENU_ITEMS[key]!
              return (
                <Col key={type}>
                  <Dropdown.Button
                    className="dropdown-btn btn-new-task"
                    icon={
                      <Card
                        bordered={false}
                        style={{ background: configs.color }}
                        bodyStyle={{ padding: 8 }}
                      >
                        <Space>
                          <IonIcon name={configs.icon} />
                          <Typography.Text>{type}</Typography.Text>
                          <IonIcon name="add-outline" />
                        </Space>
                      </Card>
                    }
                    menu={{ items: configs.items }}
                  />
                </Col>
              )
            })}
            {Object.keys(SINGLE_ITEM_MENU).map((type: any) => {
              const key: keyof typeof SINGLE_ITEM_MENU = type
              const config = SINGLE_ITEM_MENU[key]!
              return (
                <Card
                  bordered={false}
                  style={{ background: config.color }}
                  bodyStyle={{ maxHeight: 38, marginRight: 8, padding: 0 }}
                >
                  <Space>
                    {config.component}
                    <IonIcon name={config.icon} />
                  </Space>
                </Card>
              )
            })}
          </Row>
        </Card>
      </Col>

      {/* Action Button */}
      <Col span={24}>
        <Row gutter={8} justify="space-between">
          <Col flex="auto">
            <Button
              onClick={() => setStep(CreateAndUpdateTabs.SetUp)}
              icon={<IonIcon name="arrow-back-outline" />}
            >
              Back
            </Button>
          </Col>
          {isUpdateForm && (
            <Button
              loading={formLoading}
              onClick={handleUpdateBounty}
              style={{ minWidth: 130 }}
              type="primary"
            >
              Update
            </Button>
          )}
          <Col>
            <Button
              type="primary"
              style={{ minWidth: 130 }}
              onClick={() => setStep(CreateAndUpdateTabs.Prizes)}
              disabled={disabled}
            >
              <Space>
                Next
                <IonIcon name="arrow-forward-outline" />
              </Space>
            </Button>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default FormEnterTask
