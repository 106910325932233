import { useParams } from 'react-router-dom'

import { Col, Row, Typography } from 'antd'

import MultiplierWhitelist from 'components/multiplierWhitelist'

import { useWhitelistDetail } from 'hooks/whitelist/useWhitelistDetail'

function WhitelistDetails() {
  const whitelistId = useParams().whitelistId || ''
  const { data: whitelistDetails } = useWhitelistDetail(whitelistId)

  return (
    <Row gutter={[12, 12]}>
      <Col span={24}>
        <Typography.Title level={3} type="success">
          {whitelistDetails?.title}
        </Typography.Title>
      </Col>
      <Col span={24}>
        <MultiplierWhitelist
          isOnlyWallet={true} //NOTE: only get and export wallet whitelist, not including usage status of applications
          whitelistId={whitelistId}
        />
      </Col>
    </Row>
  )
}

export default WhitelistDetails
