import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDebounce } from 'react-use'

import { Button, Col, Row, Tabs, Typography } from 'antd'
import { PlusSquareOutlined as AddSquareIcon } from '@ant-design/icons'

import SearchBar from 'components/systems/searchBar'
import CustomPagination from 'components/systems/pagination'
import GachaListTable from './GachaListTable'

import { useGachaList } from 'hooks/gacha/useGachaList'
import { useCurrentCommunity } from 'hooks/community/useCurrentCommunity'

import { DEFAULT_PAGE_SIZE } from 'constant'
import { ROUTES } from 'constant/routes'

import { GachaStates } from 'types/gacha.type'

const tabItems = [
  {
    key: GachaStates.OnGoing,
    label: 'ON GOING',
  },
  {
    key: GachaStates.Ended,
    label: 'ENDED',
  },
  {
    key: GachaStates.Unpublished,
    label: 'UNPUBLISHED',
  },
  {
    key: GachaStates.Scheduled,
    label: 'SCHEDULED',
  },
]

function GachaManagement() {
  const navigate = useNavigate()

  const [search, setSearch] = useState<string>('')
  const [state, setState] = useState<GachaStates>(GachaStates.OnGoing)
  const [page, setPage] = useState<number>(1)
  const [community] = useCurrentCommunity()

  const [payload, setPayload] = useState({
    search,
    state,
    communityId: community?._id,
    page,
    pageSize: DEFAULT_PAGE_SIZE,
  })

  useDebounce(
    () =>
      setPayload({
        search,
        state,
        communityId: community?._id,
        page,
        pageSize: DEFAULT_PAGE_SIZE,
      }),
    100,
    [search, state, community?._id, page],
  )

  const { data, total, isLoading } = useGachaList(payload)

  return (
    <Row gutter={[0, 24]}>
      <Col span={24}>
        <Typography.Title level={3} type="success">
          Gacha List
        </Typography.Title>
      </Col>

      <Col span={24} style={{ width: '100%' }}>
        <Row wrap={false} gutter={10}>
          <Col flex={1}>
            <SearchBar
              setSearch={setSearch}
              placeholder="Search gacha name..."
              style={{ width: '100%' }}
            />
          </Col>

          <Col style={{ width: 180 }}>
            <Button
              icon={<AddSquareIcon />}
              type="primary"
              block
              onClick={() => navigate(ROUTES.GACHA.NEW_GACHA)}
            >
              Create New Gacha
            </Button>
          </Col>
        </Row>
      </Col>

      <Col span={24}>
        <Row>
          <Col span={24}>
            <Tabs
              activeKey={state}
              onChange={(key) => {
                setState(key as GachaStates)
                setPage(1)
              }}
              type="card"
              items={tabItems}
            />
          </Col>
          <Col span={24}>
            <GachaListTable data={data} isLoading={isLoading} />
          </Col>
          <Col span={24} style={{ marginTop: 24 }}>
            <Row justify="space-between">
              <Col>
                <Typography.Text type="success">Total: {total}</Typography.Text>
              </Col>

              <Col>
                <CustomPagination
                  total={total}
                  page={page}
                  onChange={setPage}
                  pageSize={DEFAULT_PAGE_SIZE}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  )
}

export default GachaManagement
