import { Fragment, useMemo, useState } from 'react'

import {
  Button,
  Table,
  Row,
  Space,
  Typography,
  Col,
  Popconfirm,
  Checkbox,
} from 'antd'
import { ColumnType } from 'antd/lib/table'

import DataPreview from '../dataPreview'
import IonIcon from 'components/systems/ionIcon'
import CustomPagination from 'components/systems/pagination'

import { useUploadCsv } from 'hooks/multiplier/useUploadCsv'
import { useUserWhitelist } from 'hooks/whitelist/useUserWhitelist'
import useCheckWalletWhitelistsExisted from 'hooks/whitelist/useCheckWalletWhitelistsExisted'

import { notifyError, notifySuccess, shortenAddressCustom } from 'helper'

import { DEFAULT_PAGE, MULTIPLIER_PAGE_SIZE } from 'constant'

import { IWalletWhitelist } from 'types/whitelist.type'

import './index.less'

type CsvPreviewProps = {
  whitelistId: string
  addressList: IWalletWhitelist[]
  onSetAddressList: React.Dispatch<React.SetStateAction<IWalletWhitelist[]>>
}

function CsvPreview({
  whitelistId,
  addressList,
  onSetAddressList,
}: CsvPreviewProps) {
  const [keyword, setKeyword] = useState('')
  const [isCheckAll, setIsCheckAll] = useState(false)
  const [page, setPage] = useState(DEFAULT_PAGE)
  const [selectedRowKeys, setSelectedRowKeys] = useState<React.Key[]>([])
  const { mutateAsync: onUpload, isLoading } = useUploadCsv()
  const { refetchRoot } = useUserWhitelist({ whitelistId })

  const { data, refetchRoot: recheckWalletWhitelists } =
    useCheckWalletWhitelistsExisted({
      whitelistId,
      addresses: addressList.map((item) => item.wallet.toLowerCase()),
    })

  const handleRemove = (wallet: string) => {
    const newListAddress = [...addressList]
    const walletIndex = addressList.findIndex(
      (item) => item.wallet.toString() === wallet,
    )
    if (walletIndex !== -1) {
      newListAddress.splice(walletIndex, 1)
      onSetAddressList(newListAddress)
    }
  }

  const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
    if (newSelectedRowKeys.length === addressList.length) {
      setIsCheckAll(true)
    } else if (isCheckAll) {
      setIsCheckAll(false)
    }

    setSelectedRowKeys(newSelectedRowKeys)
  }

  const handleSubmit = async () => {
    try {
      if (selectedRowKeys.length === 0) return

      const csvContent = selectedRowKeys.map((wallet) => `${wallet}`).join('\n')

      const csvBlob = new Blob([`wallet\n${csvContent}`], { type: 'text/csv' })

      const formData = new FormData()
      formData.append('file', csvBlob, 'whitelist.csv')
      formData.append('whitelistId', whitelistId)

      await onUpload(formData)
      setSelectedRowKeys([])
      await recheckWalletWhitelists()
      notifySuccess('Import address whitelists')
    } catch (error) {
      notifyError(error)
    } finally {
      await refetchRoot()
    }
  }

  const filteredAddressList = useMemo(() => {
    return addressList.filter(({ wallet }) =>
      wallet.toLowerCase().includes(keyword.toLowerCase()),
    )
  }, [keyword, addressList])

  const paginatedAddressList = useMemo(() => {
    const startIndex = (page - 1) * MULTIPLIER_PAGE_SIZE
    return filteredAddressList.slice(
      startIndex,
      startIndex + MULTIPLIER_PAGE_SIZE,
    )
  }, [page, filteredAddressList])

  const filteredSelectedRowKeys = useMemo(() => {
    return selectedRowKeys.filter((key) =>
      filteredAddressList.some((item) => item.wallet === key),
    )
  }, [selectedRowKeys, filteredAddressList])

  const addressColumn: ColumnType<IWalletWhitelist>[] = [
    {
      title: (
        <Space size={8}>
          <Typography.Text type="secondary">Selected</Typography.Text>
          <Typography.Text>{filteredSelectedRowKeys.length}</Typography.Text>
        </Space>
      ),
      dataIndex: 'wallet',
      align: 'left',
      render: (wallet: string) => (
        <Typography.Text>{shortenAddressCustom(wallet, 8, 9)}</Typography.Text>
      ),
    },
    {
      title: (
        <Space size={8}>
          <Typography.Text type="secondary">Total</Typography.Text>
          <Typography.Text>{filteredAddressList.length}</Typography.Text>
        </Space>
      ),
      dataIndex: 'wallet',
      key: 'action',
      align: 'right',
      render: (wallet: string) => (
        <Popconfirm
          title="Are you sure to delete this address?"
          onConfirm={() => handleRemove(wallet)}
        >
          <Button onClick={() => handleRemove(wallet)} className="remove-btn">
            <IonIcon name="close-outline" />
          </Button>
        </Popconfirm>
      ),
    },
  ]

  return (
    <DataPreview
      header={
        <Space wrap={false}>
          <IonIcon name="document" style={{ color: '#0FDBD1' }} />
          <Typography.Title level={5}>CSV LIST</Typography.Title>
        </Space>
      }
      dataList={
        <Row className="csv-list-section" gutter={[0, 24]} justify="center">
          {paginatedAddressList.length > 0 ? (
            <Fragment>
              <Col span={24}>
                <Checkbox
                  onChange={(e) => {
                    setIsCheckAll(e.target.checked)
                    if (e.target.checked) {
                      const newSelectedRowKeys = addressList
                        .filter(
                          ({ wallet }) =>
                            wallet
                              .toLowerCase()
                              .includes(keyword.toLowerCase()) &&
                            (data
                              ? !data.includes(wallet.toLowerCase())
                              : true),
                        )
                        .map((item) => item.wallet)

                      return setSelectedRowKeys(newSelectedRowKeys)
                    }
                    return setSelectedRowKeys([])
                  }}
                  checked={isCheckAll}
                >
                  Select all
                </Checkbox>
              </Col>
              <Col span={24}>
                <Table
                  rowKey="wallet"
                  rowSelection={{
                    selectedRowKeys: filteredSelectedRowKeys,
                    onChange: onSelectChange,
                    getCheckboxProps: (record) => ({
                      disabled:
                        data && data.includes(record.wallet.toLowerCase()),
                      name: record.wallet,
                    }),
                  }}
                  dataSource={paginatedAddressList}
                  columns={addressColumn}
                  pagination={false}
                  style={{ height: 525 }}
                />
              </Col>

              <Col span={24} style={{ textAlign: 'center' }}>
                <CustomPagination
                  total={filteredAddressList.length}
                  onChange={setPage}
                  page={page}
                  pageSize={MULTIPLIER_PAGE_SIZE}
                />
              </Col>

              <Col span={24} style={{ textAlign: 'center' }}>
                <Button
                  type="primary"
                  style={{ width: 160 }}
                  onClick={handleSubmit}
                  disabled={!filteredSelectedRowKeys.length}
                  loading={isLoading}
                >
                  Submit
                </Button>
              </Col>
            </Fragment>
          ) : (
            <Col>
              <Typography.Text type="secondary">No Data Here</Typography.Text>
            </Col>
          )}
        </Row>
      }
      onSearch={setKeyword}
    />
  )
}

export default CsvPreview
