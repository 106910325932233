import React, { useCallback, useContext } from 'react'
import { useParams } from 'react-router-dom'

import { Button, Col, Row, Space } from 'antd'
import SearchBar from 'components/systems/searchBar'
import { ApplicationFrameContext } from '../applicationFrame/ApplicationFrameContext'

import { useBulkCreateMultiApp } from 'hooks/multiplier/useBulkCreateMultiApp'
import { useMultiApps } from 'hooks/multiplier/useMultiApps'

import { notifyError, notifySuccess } from 'helper'

type DropdownCustomProps = {
  menu: React.ReactElement<any, string | React.JSXElementConstructor<any>>
}

export default function DropdownCustom({ menu }: DropdownCustomProps) {
  const multiplierId = useParams().multiplierId || ''
  const { mutateAsync: bulkCreateMultiApp, isLoading: isCreating } =
    useBulkCreateMultiApp()
  const {
    applicationType,
    setSearch,
    selectedApplications,
    setSelectedApplications,
  } = useContext(ApplicationFrameContext)
  const { refetch } = useMultiApps({
    multiplierId,
    applicationType,
  })

  const onApply = useCallback(async () => {
    try {
      await bulkCreateMultiApp({
        applicationIds: selectedApplications,
        multiplierId: multiplierId,
        applicationType,
      })

      setSelectedApplications([])
      await refetch()
      notifySuccess('Created multiplier applications')
    } catch (error) {
      notifyError(error)
    }
  }, [
    applicationType,
    bulkCreateMultiApp,
    multiplierId,
    refetch,
    selectedApplications,
    setSelectedApplications,
  ])

  return (
    <Row
      gutter={[12, 12]}
      style={{
        padding: 12,
      }}
    >
      <Col span={24}>
        <SearchBar
          setSearch={setSearch}
          placeholder="Search "
          style={{ width: '100%', borderRadius: 8 }}
        />
      </Col>
      <Col span={24}>{menu}</Col>
      <Col span={24}>
        <Space style={{ width: '100%', justifyContent: 'end' }}>
          <Button type="primary" onClick={onApply} loading={isCreating}>
            Apply
          </Button>
        </Space>
      </Col>
    </Row>
  )
}
