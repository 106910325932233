import type { IVariant } from 'types/product.type'
import type { ChainID } from 'constant'
import type { FindDto } from 'types'
import { TokenStandard } from 'types/token.type'

/**
 * Gacha Base Info
 */

// TODO: align with BE later
export interface IGachaBaseInfo {
  _id?: string

  title: string

  subtitle?: string

  thumbnail: string

  communityId: string

  startedAt: string

  endedAt: string

  isActive?: boolean

  slug: string

  variants: IVariant[]
}

export enum GachaStates {
  OnGoing = 'GC_STATE:ON_GOING',
  SoldOut = 'GC_STATE:SOLD_OUT',
  Unpublished = 'GC_STATE:UNPUBLISHED',
  Ended = 'GC_STATE:ENDED',
  Scheduled = 'GC_STATE:SCHEDULED',
}

export type FindAllGacha = {
  communityId?: string

  state?: GachaStates
} & FindDto
export type CreateGachaBaseInfoDto = IGachaBaseInfo
export type UpdateGachaBaseInfoDto = Partial<IGachaBaseInfo>

export type MintTicketDto = {
  quantity: number
  uid: string
}

export type BulkMintTicketDto = {
  gachaId: string

  ticketList: MintTicketDto[]
}

/**
 * Gacha Reward
 */

export interface IEPRewardConfig {
  amount: number
}

export interface IBadgeRewardConfig {
  badgeId: string

  amount: number
}

export interface IWhitelistRewardConfig {
  whitelistId: string

  amount: number
}

export interface IGiftCardRewardConfig {
  amount: number

  digitalCardId: string
}

export interface INFTRewardConfig {
  amount: number

  tokenId: number

  tokenAddress: string

  tokenStandard: TokenStandard.ERC721 | TokenStandard.ERC1155

  chainId: ChainID
}

export interface ITokenRewardConfig {
  tokenAddress: string

  symbol: string

  spent: number

  amount: number

  chainId: ChainID
}

export interface IGachaReward {
  _id?: string

  gachaId: string

  tier: GachaRewardTier

  type: GachaRewardType

  total: number

  available: number

  weight: number

  config:
    | IEPRewardConfig
    | IBadgeRewardConfig
    | IGiftCardRewardConfig
    | INFTRewardConfig
    | ITokenRewardConfig
    | IWhitelistRewardConfig
}

export enum GachaRewardType {
  EP = 'GC_REWARD_TYPE:EP',
  NFT = 'GC_REWARD_TYPE:NFT',
  Token = 'GC_REWARD_TYPE:TOKEN',
  Badge = 'GC_REWARD_TYPE:BADGE',
  Whitelist = 'GC_REWARD_TYPE:WHITELIST',
  GiftCard = 'GC_REWARD_TYPE:GIFT_CARD',
  Congrats = 'GC_REWARD_TYPE:CONGRATS',
}

export type FindGachaRewardDto = { gachaId: string } & FindDto
export type UpsertGachaRewardDto = IGachaReward[]

export interface IGacha extends IGachaBaseInfo {
  rewards: IGachaReward[]
}

export enum GachaRewardTier {
  Common = 'GC_REWARD_TIER:COMMON',
  Rare = 'GC_REWARD_TIER:RARE',
  Epic = 'GC_REWARD_TIER:EPIC',
  Legendary = 'GC_REWARD_TIER:LEGENDARY',
}

export const GachaRewardLabel: Record<GachaRewardType, string> = {
  [GachaRewardType.EP]: 'EP',
  [GachaRewardType.Whitelist]: 'Whitelist',
  [GachaRewardType.GiftCard]: 'Digital Card',
  [GachaRewardType.NFT]: 'NFT',
  [GachaRewardType.Token]: 'Token',
  [GachaRewardType.Badge]: 'Badge',
  [GachaRewardType.Congrats]: 'Congrats',
}

export enum CountGachaTicketType {
  All = 'COUNT_GACHA_TICKET_TYPE:ALL',
  Spun = 'COUNT_GACHA_TICKET_TYPE:SPUN',
}

export type CountGachaTicketDto = {
  gachaIds: string[]

  type: CountGachaTicketType
}

export type CountGachaTicket = {
  gachaId: string

  count: number
}

export interface IGachaWithCount extends IGachaBaseInfo {
  totalTicket: number

  totalSpun: number
}
